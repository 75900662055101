<template>
  <div
    class="fixed md:absolute top-8 md:-top-6 md:mt-2 left-0 md:left-auto right-0 md:right-1 lg:right-4 bg-white z-10 md:w-74 p-4 pt-2 md:p-1 shadow-blur-glow md:shadow-md rounded-t-xl md:rounded-lg h-full md:h-auto"
  >
    <button
      @click="$emit('close')"
      class="absolute right-0 px-4 py-1 text-action font-semibold md:hidden"
    >
      Done
    </button>
    <div v-on-clickaway="closeExpandSort">
      <div class="my-2 hidden md:block">
        <button @click="expandSort = !expandSort" class="px-3 w-full text-left">
          <div class="inline-block transition-transform duration-200" :style="flip(expandSort)">
            <font-awesome-icon icon="chevron-down" class="-mb-px" />
          </div>
          <h4 class="ml-3 inline-block">Sort by: {{ selected }}</h4>
        </button>
      </div>
      <transition name="expandDown">
        <div v-show="expandSort" class="flex flex-col text-left text-lg mt-12 md:mt-0 md:pb-1">
          <button @click="setSort('A to Z')" class="sort-buttons" :class="isSelected('A to Z')">
            <h3 class="md:text-lg">A to Z</h3>
          </button>
          <button @click="setSort('Z to A')" class="sort-buttons" :class="isSelected('Z to A')">
            <h3 class="md:text-lg">Z to A</h3>
          </button>
          <button
            @click="setSort('Price Low to High')"
            class="sort-buttons"
            :class="isSelected('Price Low to High')"
          >
            <h3 class="md:text-lg">Price Low to High</h3>
          </button>
          <button
            @click="setSort('Price High to Low')"
            class="sort-buttons"
            :class="isSelected('Price High to Low')"
          >
            <h3 class="md:text-lg">Price High to Low</h3>
          </button>
          <button
            @click="setSort('Popularity')"
            class="sort-buttons"
            :class="isSelected('Popularity')"
          >
            <h3 class="md:text-lg">Popularity</h3>
          </button>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway';

export default {
  mixins: [clickaway],
  data: () => {
    return {
      expandSort: innerWidth <= 900,
    };
  },
  computed: {
    selected: {
      get() {
        return this.$store.state.filter.sort;
      },
      set(val) {
        this.setSort(val);
      },
    },
  },
  created() {
    addEventListener('resize', () => {
      this.expandSort = innerWidth <= 900;
    });
  },
  mounted() {
    this.expandSort = innerWidth <= 900;
  },
  methods: {
    setSort(value) {
      if (window.innerWidth > 900) this.expandSort = false;
      this.$store.commit('filter/setSort', value);

      if (window.innerWidth <= 900) {
        this.expandSort = true;
        this.$emit('close');
      }
      // const filters = this.$store.getters['filter/getSelectedFilters'];
      // const query = this.$store.getters['filter/getProcessedFilters'](
      //   filters,
      //   {
      //     ...this.$route.query,
      //   },
      //   false,
      //   {
      //     sort: value,
      //   }
      // );
      // this.$router.push({
      //   name: this.$route.name,
      //   query,
      // });
    },
    isSelected(value) {
      if (value === this.selected) {
        return 'bg-blue-200 rounded shadow-md';
      }
    },
    flip(value) {
      if (value) {
        return 'transform:rotate(180deg);';
      }
      return '';
    },
    closeExpandSort() {
      if (innerWidth > 900) {
        this.expandSort = false;
      }
    },
  },
};
</script>

<style scoped>
.sort-buttons {
  text-align: left;
  margin: 0 0.5rem;
  padding: 8px 1rem;
  border-radius: 0.75rem;
}

/* Expand down - similar to scaleDown, but not for absolute elements */
.expandDown-enter,
.expandDown-leave-to {
  max-height: 0px;
}
.expandDown-enter-to,
.expandDown-leave {
  max-height: 14rem;
}
.expandDown-enter-active,
.expandDown-leave-active {
  overflow: hidden;
  transition: max-height 0.2s;
}
</style>
