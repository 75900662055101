<template>
  <div
    class="fixed top-8 md:top-4 bottom-0 md:mt-4 left-0 right-0 bg-white md:bg-gray-100 z-10 md:w-72 p-4 pt-2 shadow-blur-glow md:shadow-inner rounded-t-xl md:rounded-t-none md:pb-10 overflow-y-auto scrollbar-w-2 scrollbar-track-gray-lighter scrollbar-thumb-rounded scrollbar-thumb-gray"
    id="filterContainer"
  >
    <div v-if="loading">
      <Loading />
    </div>
    <div v-else>
      <button
        @click="$emit('close')"
        class="absolute right-0 px-6 py-1 text-action font-semibold md:hidden"
      >
        Done
      </button>

      <div class="mt-1 mb-2 text-center text-lg" :key="filterKey">
        <h4 class="inline-block">Filters</h4>
        <span class="text-gray-500"> - {{ filterCount }} applied</span>
      </div>
      <button
        @click="clearAllFilters()"
        :class="filterCount > 0 ? 'opacity-100' : 'invisible opacity-0'"
        class="w-full h-8 bg-gray-300 relative text-center shadow-md rounded-full md:my-1 overflow-hidden transition-all duration-300"
      >
        Remove All Filters
        <div class="absolute right-0 mr-3 top-0 mt-1 cursor-pointer">
          <font-awesome-icon icon="times" />
        </div>
      </button>

      <!-- price Filter -->
      <div
        class="bg-white shadow-md rounded-lg p-2 my-3 relative transition-colors duration-300"
        :class="priceSelected()"
      >
        <button @click="expandPrice = !expandPrice" class="px-3 w-full text-left">
          <div class="inline-block transition-transform duration-200" :style="flip(expandPrice)">
            <font-awesome-icon icon="chevron-down" class="-mb-px" />
          </div>
          <h4 class="ml-3 inline-block">Price</h4>
        </button>
        <button
          v-if="minPrice != 0 || maxPrice !== $store.state.filter.initialMaxPrice"
          class="absolute right-0 top-0 px-3 py-2 mr-1 mt-1 cursor-pointer"
          @click="clearPrice()"
        >
          <font-awesome-icon icon="times" class="text-lg" />
        </button>
        <transition name="expandDownSmall">
          <div v-show="expandPrice" class="flex justify-around mt-2">
            <div class="relative mx-1">
              <span class="absolute ml-1 mt-2">$</span>
              <input
                type="number"
                step="any"
                min="0"
                @change="setMinPrice()"
                :value="minPrice"
                id="minPriceInput"
                placeholder="Min"
                class="border-gray-300 border pl-4 w-full"
              />
            </div>
            <div class="relative mx-1">
              <span class="absolute ml-1 mt-2">$</span>
              <input
                type="number"
                step="any"
                min="0"
                id="maxPriceInput"
                :value="maxPrice"
                @change="setMaxPrice()"
                class="border-gray-300 border pl-4 w-full"
              />
            </div>
          </div>
        </transition>
      </div>
      <!-- End of price Filter -->

      <!-- Availability Filter -->
      <div
        class="bg-white shadow-md rounded-md p-2 my-3 transition-colors duration-300 relative"
        :key="filterKey + 1"
        :class="inStock || outStock ? 'bg-blue-200' : null"
      >
        <button @click="expandAvailability = !expandAvailability" class="px-3 w-full text-left">
          <div
            class="inline-block transition-transform duration-200"
            :style="flip(expandAvailability)"
          >
            <font-awesome-icon icon="chevron-down" class="-mb-px" />
          </div>
          <h4 class="ml-3 inline-block">Availability</h4>
        </button>
        <button
          v-if="inStock || outStock"
          class="absolute right-0 top-0 px-3 py-2 mr-1 mt-1 cursor-pointer"
          @click="clearAvailable()"
        >
          <font-awesome-icon icon="times" class="text-lg" />
        </button>

        <transition name="expandDownSmall">
          <div v-show="expandAvailability" class="mt-2 px-3">
            <div>
              <input
                type="checkbox"
                id="inStock"
                class="mr-2 cursor-pointer styled border-gray-400 border"
                v-model="inStock"
                :checked="inStock"
                @change="filterKey++"
              />
              <label for="inStock" class="cursor-pointer">In stock</label>
            </div>
            <div>
              <input
                type="checkbox"
                id="outStock"
                class="mr-2 cursor-pointer styled border-gray-400 border"
                v-model="outStock"
                :checked="outStock"
                @change="filterKey++"
              />
              <label for="outStock" class="cursor-pointer">Out of stock</label>
            </div>
          </div>
        </transition>
      </div>
      <!-- Availability Filter End-->

      <!-- Dynamic filter -->
      <div v-for="(key, index) in filterNames" :key="key">
        <div
          v-if="getFilterValueSize(key) > 0"
          class="shadow-lg rounded-lg p-2 bg-white my-3 relative transition-colors duration-150"
          :class="filterOptionActive(key) ? 'bg-blue-200' : null"
        >
          <button @click="setExpandFilter(index)" class="px-3 w-full text-left">
            <div
              class="inline-block transition-transform duration-200"
              :style="flip(expandFilter[index])"
            >
              <font-awesome-icon icon="chevron-down" class="-mb-px" />
            </div>
            <h4 class="ml-3 inline">{{ key }}</h4>
          </button>

          <button
            v-if="filterOptionActive(key)"
            class="absolute right-0 top-0 px-3 py-2 mr-1 mt-1 cursor-pointer"
            @click="clearFilter(key)"
          >
            <font-awesome-icon icon="times" class="text-lg" />
          </button>

          <transition name="expandDownLarge">
            <div
              v-show="expandFilter[index]"
              class="mt-2 pl-3 overflow-x-auto scrollbar-w-2 scrollbar-track-gray-lighter scrollbar-thumb-rounded scrollbar-thumb-gray"
            >
              <div :id="key">
                <div v-for="value in getFilterValues(key)" :key="value" class="flex">
                  <input
                    type="checkbox"
                    :id="`${value}-${key}`"
                    class="my-1 cursor-pointer border-gray-400 border"
                    :class="key"
                    :checked="filterOptionSelectValue(key, value)"
                    @change="addFilter(key, value)"
                  />
                  <label
                    :for="`${value}-${key}`"
                    class="capitalize flex cursor-pointer"
                    :class="isAvailable(value, key) ? '' : 'text-gray-600'"
                  >
                    <div
                      v-if="key === 'Color'"
                      class="w-5 h-5 flex justify-center align-middle mx-2"
                      v-html="getVariantColor(value)"
                    ></div>
                    <div v-else class="mx-1"></div>
                    <div class="whitespace-no-wrap">
                      {{ value }}
                    </div>
                  </label>
                </div>
              </div>
            </div>
          </transition>
        </div>
      </div>
      <!-- End of dynamic filter -->
    </div>
  </div>
</template>

<script>
import Loading from '@/components/shared/Loading';

export default {
  props: {
    queryParams: {
      type: Object,
    },
  },
  data: () => {
    let largeScreen = window.innerWidth >= 900;

    return {
      filterKey: 0,
      largeScreen: largeScreen,
      expandPrice: largeScreen,
      expandAvailability: largeScreen,
      expandFilter: [],
    };
  },
  watch: {
    largeScreen(value) {
      this.expandPrice = value;
      this.expandAvailability = value;
      this.expandFilter = new Array(this.filterNames.length).fill(value);
    },
    filterCount() {
      this.filterKey++;
    },
  },
  computed: {
    loading() {
      return this.$store.getters['filter/getLoading'];
    },
    filterCount: {
      get() {
        return this.$store.getters['filter/getFilterCount'];
      },
    },
    minPrice: {
      set(val) {
        this.filterKey++;
        this.$store.commit('filter/setMinPrice', val);
      },
      get() {
        return this.$store.state.filter.minPrice;
      },
    },
    maxPrice: {
      set(val) {
        this.filterKey++;
        this.$store.commit('filter/setMaxPrice', val);
      },
      get() {
        return this.$store.state.filter.maxPrice;
      },
    },
    inStock: {
      set(val) {
        this.filterKey++;
        this.$store.commit('filter/setFilterInStock', val);
        this.$store.commit('filter/setFilterOutStock', false);
      },
      get() {
        return this.$store.getters['filter/getInStock'];
      },
    },
    outStock: {
      set(val) {
        this.filterKey++;
        this.$store.commit('filter/setFilterInStock', false);
        this.$store.commit('filter/setFilterOutStock', val);
      },
      get() {
        return this.$store.getters['filter/getOutStock'];
      },
    },
    filterNames: {
      get() {
        return this.$store.getters['filter/getFilterNames'];
      },
    },
    initialPrice() {
      return this.$store.state.filter.initialMaxPrice;
    },
  },
  methods: {
    pushRouteWithParams(clearFilters = false) {
      // setTimeout(() => {
      const filters = this.$store.getters['filter/getSelectedFilters'];
      const query = this.$store.getters['filter/getProcessedFilters'](
        filters,
        this.queryParams,
        clearFilters
      );
      this.$router
        .push({
          name: this.$route.name,
          query,
        })
        .catch((error) => {
          if (
            error.name !== 'NavigationDuplicated' &&
            !error.message.includes('Avoided redundant navigation to current location')
          ) {
            console.log(error);
          }
        });
      // }, 0);
    },
    priceSelected() {
      if (this.minPrice !== 0 || this.maxPrice != this.$store.state.filter.initialMaxPrice)
        return 'bg-blue-200';
      return null;
    },
    getVariantColor(color) {
      return this.$store.getters['filter/getVariantColor'](color);
    },
    setMinPrice() {
      const value = document.getElementById('minPriceInput').value;
      this.minPrice = value;
    },
    setMaxPrice() {
      const value = document.getElementById('maxPriceInput').value;
      this.maxPrice = value;
    },
    clearPrice() {
      this.minPrice = 0;
      this.maxPrice = this.$store.state.filter.initialMaxPrice;
    },
    clearAvailable() {
      this.inStock = false;
      this.outStock = false;
    },
    getFilterValues(key) {
      return this.$store.getters['filter/getFilterValues'](key);
    },
    getFilterValueSize(key) {
      return this.$store.getters['filter/getFilterValueSize'](key);
    },
    /**
     * key is the option Name and value is the option value,
     */
    addFilter(key, value) {
      this.$store.commit('filter/alterOptionFilter', { name: key, value });
    },
    clearAllFilters() {
      this.$store.commit('filter/clearAllFilters');
      this.filterNames.forEach((key) => {
        const classes = document.getElementsByClassName(key);
        classes.forEach((el) => {
          el.checked = false;
        });
      });
      this.filterKey++;
      // this.pushRouteWithParams(true);
    },
    clearFilter(key) {
      const classes = document.getElementsByClassName(key);
      classes.forEach((el) => {
        el.checked = false;
      });
      this.filterKey++;
      // this.inStock = !this.inStock;
      this.$store.commit('filter/clearFilter', key);
      // this.inStock = !this.inStock;
    },
    filterOptionActive(key) {
      if (this.$store.state.filter.options.has(key)) {
        return true;
      }
      return false;
    },
    filterOptionSelectValue(key, value) {
      if (
        this.$store.state.filter.options.has(key) &&
        this.$store.state.filter.options.get(key).includes(value)
      ) {
        return true;
      }
      return false;
    },
    increaseHeight(key) {
      const container = document.getElementById(key);
      const icon = document.getElementById(`${key}-icon`);
      //if already expanded
      if (container.classList.contains('h-64')) {
        icon.style.transform = 'rotate(0deg)';

        container.classList.remove('h-64');
      } else {
        //expand
        icon.style.transform = 'rotate(180deg)';

        container.classList.add('h-64');
      }
    },
    flip(value) {
      if (value) {
        return 'transform:rotate(180deg);';
      }
      return '';
    },
    setExpandFilter(index) {
      this.$set(this.expandFilter, index, !this.expandFilter[index]);
    },
    //if there are any filters selected, verify whether the option is available.
    isAvailable(key, value) {
      return this.$store.getters['filter/getIsAvailable'](key, value);
    },
  },
  mounted() {},
  components: {
    Loading,
  },
};
</script>

<style scoped>
.w-10px {
  width: 10px;
}
</style>
