<template>
  <div class="min-h-screen bg-gray-100 sm:bg-white pb-4 md:pb-0">
    <div class="w-full sm:flex min-h-screen">
      <!-- Filter pane -->
      <div
        class="relative transition-opacity duration-200 z-10 md:z-0 md:w-96"
        :class="getFilterVisibility"
      >
        <div class="modal-mask md:hidden" style="z-index: 1"></div>
        <ProductFilter v-bind:queryParams="queryParams" @close="showFilter = false" />
      </div>
      <div class="w-full relative sm:pb-20">
        <BreadCrumbs />
        <div
          class="relative transition-opacity duration-200 z-10 md:z-1"
          :class="getSortVisibility"
        >
          <div class="modal-mask md:hidden" style="z-index: 1"></div>
          <Sort @close="showSort = false" />
        </div>

        <!-- Buttons for mobile -->
        <div class="md:hidden mb-4 mt-8 flex justify-around">
          <button class="btn-white rounded-full w-40" @click="displayFilter(true)">
            <img src="/images/icons/Filter.svg" alt="Filter icon" class="inline-block mb-1 mr-2" />
            <span>Filters</span>
            <div class="font-normal text-sm">
              <strong>{{ filterCount }}</strong> applied
            </div>
          </button>
          <button class="btn-white rounded-full w-40" @click="displaySort(true)">
            <img src="/images/icons/Sort.svg" alt="Filter icon" class="inline-block mb-px mr-1" />
            Sort
            <div class="font-normal text-sm">{{ sort }}</div>
          </button>
        </div>

        <!-- The product section -->
        <div v-if="loading">
          <Loading />
        </div>
        <div v-else class="sm:min-h-2/3-screen">
          <!-- End buttons for mobile -->
          <div v-if="products.length > 0">
            <transition-group
              name="product"
              tag="div"
              class="flex-wrap flex justify-around md:justify-start"
            >
              <ProductTile
                :id="'product' + index"
                v-for="(product, index) in products"
                :key="`${product.variants[0]._id}-${index}`"
                :productInfo="product"
                :index="index"
                :length="products.length"
              />
            </transition-group>
          </div>
          <div v-else class="mt-16 md:p-8 text-center">
            <h3>No Products Found</h3>
            <img
              src="/images/static/empty.svg"
              class="w-64 h-64 mx-auto mt-4"
              alt="Image of an empty cart"
            />
          </div>
        </div>
        <Pagination
          class="sm:absolute bottom-1 sm:w-11/12"
          pageType="Product"
          :admin="false"
          :totalAvailable="totalAvailable"
          :limit="limit"
          :currentPage="currentPage"
          :selectableLimits="selectableLimits"
          @setCurrentPage="setCurrentPage"
          @setLimit="setLimit"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ProductTile from '@/components/items/ProductTile';
import ProductFilter from '@/components/items/ProductFilter';
import Sort from '@/components/items/Sort';
import BreadCrumbs from '@/components/shared/BreadCrumbs';
import Loading from '@/components/shared/Loading';
import Pagination from '@/components/shared/Pagination';
import { urlencoded } from 'body-parser';

export default {
  data: () => {
    return {
      showFilter: false,
      showSort: false,
      mobile: window.innerWidth <= 900,
      queryParams: null,
      selectableLimits: [50, 100, 200, 500],
    };
  },

  computed: {
    filteredProducts() {
      return this.$store.getters['products/getProducts'];
    },
    currentPage: {
      get() {
        return this.$store.getters['products/getCurrentPage'];
      },
      set(val) {
        this.$store.commit('products/setCurrentPage', val);
      },
    },
    limit: {
      get() {
        return this.$store.getters['products/getLimit'];
      },
      set(val) {
        this.$store.commit('products/setLimit', val);
      },
    },

    filterCount() {
      return this.$store.getters['filter/getFilterCount'];
    },

    getFilterVisibility() {
      if (!this.mobile) {
        return '';
      }
      if (!this.showFilter) {
        return 'invisible opacity-0';
      }
      return '';
    },
    getSortVisibility() {
      if (!this.mobile) {
        return '';
      }
      if (!this.showSort) {
        return 'invisible opacity-0';
      }
      return '';
    },
    sort() {
      return this.$store.getters['filter/getSort'];
    },
    outStock() {
      return this.$store.getters['products/getOutStock'];
    },
    inStock() {
      return this.$store.getters['products/getInStock'];
    },
    loading() {
      return this.$store.getters['products/getLoading'];
    },
    loadingFilter() {
      return this.$store.getters['filter/getLoading'];
    },
    totalAvailable() {
      return this.$store.getters['filter/getFilteredProductCount'];
    },
    products() {
      return this.$store.getters['products/getLimitedProducts'];
    },
    pageTitle() {
      return urlencoded(this.$route.query.title);
    },
  },
  watch: {
    showFilter() {
      document.getElementsByTagName('BODY')[0].classList.toggle('overflow-hidden');
    },
    showSort() {
      document.getElementsByTagName('BODY')[0].classList.toggle('overflow-hidden');
    },
    loadingFilter() {
      this.scrollToPriorLocation();
    },
    loading() {
      this.scrollToPriorLocation();
    },
    pageTitle() {
      this.fetchProducts();
    },
  },
  methods: {
    /**
     * to get this to fire correctly, you have to have the router link look like either of the examples:
     * <router-link :to="{ path: '/products, query: {type: '<normal or _____>', <Field>: '<Property>'}}">
     * or programmatically
     * this.$router.push({ path: '/products', query: {type: '<normal or _____>', <Field>: '<Property>'}})
     * */
    async fetchProducts() {
      // this.loadFilters();
      const query = { ...this.$route.query };

      this.$store.commit('products/clearProducts');

      if (query.type == 'normal' || query.type === undefined) {
        delete query.type;
        await this.$store.dispatch('products/getProducts', query);
      } else if (query.type == 'suggested') {
        await this.$store.dispatch('products/getAllSuggested');
      } else if (query.type === 'Recent Purchases') {
        await this.$store.dispatch('products/getRecentPurchases');
      } else if (query.type === 'search') {
        await this.$store.dispatch('products/search', {
          title: encodeURIComponent(query.title),
        });
      }
    },

    displayFilter(value) {
      this.showFilter = value;
    },
    displaySort(value) {
      this.showSort = value;
    },

    closeFilter() {
      this.showFilter = false;
    },
    // loadFilters() {
    //   this.$store.commit('filter/clearAllFilters');
    //   this.queryParams = { ...this.$route.query };
    //   const excludeKeys = [
    //     'type',
    //     'categories',
    //     'subcategories',
    //     'tags',
    //     'title',
    //     'pindex',
    //     'scrollTo',
    //   ];
    //   const { inStock, outStock, minPrice, maxPrice, sort, ...filters } = this.queryParams;
    //   const keys = Object.keys(filters);
    //   if (keys.length > 0) {
    //     for (let index = 0; index < keys.length; index++) {
    //       const key = keys[index];
    //       if (!excludeKeys.includes(key)) {
    //         // this.setExpandFilter(this.$store.getters['products/getFilterNames'].indexOf(key));
    //         if (filters[key] instanceof Array) {
    //           for (let j = 0; j < filters[key].length; j += 1) {
    //             this.$store.commit('products/alterOptionFilter', {
    //               name: key,
    //               value: filters[key][j],
    //             });
    //           }
    //         } else {
    //           this.$store.commit('products/alterOptionFilter', { name: key, value: filters[key] });
    //         }
    //       }
    //     }
    //   }
    //   if (inStock !== undefined && inStock == true) {
    //     this.$store.commit('products/setFilterInStock', true);
    //     this.$store.commit('products/setFilterOutStock', false);
    //   } else if (outStock !== undefined && outStock == true) {
    //     this.$store.commit('products/setFilterInStock', false);
    //     this.$store.commit('products/setFilterOutStock', true);
    //   }

    //   if (minPrice !== undefined) {
    //     this.$store.commit('products/setMinPrice', minPrice);
    //   }

    //   if (maxPrice !== undefined) {
    //     this.$store.commit('products/setMaxPrice', maxPrice);
    //   }

    //   if (sort) {
    //     this.$store.commit('products/setSort', sort);
    //   }
    // },
    setLimit(payload) {
      this.currentPage = 1;
      this.limit = payload;
    },
    setCurrentPage(payload) {
      this.currentPage = payload;
    },
    scrollToPriorLocation() {
      if (!this.loading && !this.loadingFilter) {
        if (this.$route.query.scrollTo) {
          setTimeout(() => {
            window.scrollTo({
              left: 0,
              top: this.$route.query.scrollTo,
            });
            console.log('fired');
          }, 500);
        }
      }
    },
    scroll() {
      /**
       * This function is only used for desktop. This will add a class to the product filter wrapper.
       * The class will enhance the usibility of the filter when the screen is scrolled to the bottom of the screen.
       * Look in file "ProductFilter.vue" to see what this function effects.
       */
      if (!this.mobile)
        window.onscroll = () => {
          const bottomOfWindow =
            document.documentElement.scrollTop + window.innerHeight >=
            document.documentElement.offsetHeight - 180;
          const container = document.getElementById('filterContainer');
          if (container)
            if (bottomOfWindow) {
              container.classList.replace('bottom-0', 'bottom-12');
            } else {
              if (container.classList.contains('bottom-12')) {
                container.classList.replace('bottom-12', 'bottom-0');
              }
            }
        };
    },
  },
  components: {
    ProductTile,
    ProductFilter,
    BreadCrumbs,
    Sort,
    Loading,
    Pagination,
  },
  mounted() {
    this.fetchProducts();
    this.scroll();
  },
  created() {
    addEventListener('resize', () => {
      this.mobile = innerWidth <= 900;
    });
  },
  metaInfo() {
    const query = this.$route.query;
    if (query.type === 'normal') {
      if (query.categories && query.subcategories) {
        return {
          title: `${query.subcategories} in ${query.categories}`,
          meta: [
            {
              name: 'description',
              content:
                'Click here for Page Custom Supply’s broad inventory of Top Hardware Supply & Tools. PCS, a Top Online Hardware Supply Store for Individuals and Professionals!',
            },
            { name: 'keywords', content: 'online hardware supply' },
          ],
        };
      } else if (query.categories) {
        return {
          title: ` ${query.categories}`,
          meta: [
            {
              name: 'description',
              content:
                'Click here for Page Custom Supply’s broad inventory of Top Hardware Supply & Tools. PCS, a Top Online Hardware Supply Store for Individuals and Professionals!',
            },
            { name: 'keywords', content: 'online hardware supply' },
          ],
        };
      } else {
        return {
          title: `All Products - Page Custom Supply`,
          meta: [
            {
              name: 'description',
              content:
                'Click here for Page Custom Supply’s broad inventory of Top Hardware Supply & Tools. PCS, a Top Online Hardware Supply Store for Individuals and Professionals!',
            },
            { name: 'keywords', content: 'online hardware supply' },
          ],
        };
      }
    } else if (query.type === 'search') {
      return {
        title: `Products: ${query.title}`,
        meta: [
          {
            name: 'description',
            content:
              'Click here for Page Custom Supply’s broad inventory of Top Hardware Supply & Tools. PCS, a Top Online Hardware Supply Store for Individuals and Professionals!',
          },
          { name: 'keywords', content: 'online hardware supply' },
        ],
      };
    }
  },
};
</script>
