<template>
  <div
    class="relative w-full bg-white"
    :class="
      $route.name == 'Category Product' || $route.name == 'Products' || $route.name == 'Search'
        ? 'px-4 md:px-6 shadow-blur-glow md:shadow-none md:pt-8 pt-2 sm:pt-4'
        : $route.name == 'Product'
        ? 'shadow-none px-6 md:px-0 lg:pl-10'
        : 'px-4 md:px-12 lg:px-24 shadow-blur-glow md:shadow-none md:pt-8 pt-2 sm:pt-4'
    "
  >
    <h1
      v-if="pageTitle"
      class="font-medium leading-none sm:inline-block sm:mr-8"
      style="word-break: break-word"
    >
      {{ pageTitle }}
    </h1>
    <a
      v-if="$route.name === 'Categories' || $route.name === 'Subcategories'"
      @click="viewAll"
      class="mt-1 py-1 sm:inline-block font-medium text-sm cursor-pointer"
    >
      View all products
      <span v-if="pageTitle !== 'Categories'">in {{ pageTitle }}</span>
    </a>

    <!-- Breadcrumbs -->
    <div class="breadcrumb overflow-x-auto whitespace-no-wrap py-2">
      <router-link to="/">Home</router-link>

      <!-- All Categories page -->

      <span v-if="categoryPipeLine">&#187;</span>
      <router-link v-if="categoryPipeLine" :to="{ name: 'Categories', params: { sub: 'category' } }"
        >Categories</router-link
      >

      <!-- Category Specific page -->

      <span v-if="category != null">&#187;</span>
      <router-link
        v-if="category != null"
        :to="{ name: 'Subcategories', params: { sub: category } }"
        >{{ category }}</router-link
      >

      <!-- SubCategory page -->

      <span v-if="subcategory != null && $route.name != 'Product'">&#187;</span>
      <span
        v-if="subcategory != null && $route.name != 'Product'"
        class="mx-1 text-primary-dark cursor-pointer"
      >
        {{ subcategory }}
      </span>

      <!-- Sub Category page for product page -->
      <span v-if="subcategory != null && $route.name === 'Product'">&#187;</span>
      <router-link
        :to="{
          name: 'Category Product',
          query: { type: 'normal', categories: category, subcategories: subcategory },
        }"
        v-if="subcategory != null && $route.name === 'Product'"
        class="mx-1 text-primary-dark cursor-pointer"
      >
        {{ subcategory }}
      </router-link>

      <!-- Search -->
      <span v-if="search != null">&#187;</span>
      <router-link v-if="search != null" :to="{ name: 'Categories', params: { sub: category } }">
        {{ search }}
      </router-link>

      <!-- Product Name -->
      <span v-if="$route.name === 'Product'">&#187;</span>
      <span v-if="$route.name === 'Product'" class="mx-1 text-primary-dark cursor-pointer">
        {{ productName }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  data: () => {
    return {
      categoryPipeLine: true,
    };
  },
  computed: {
    pageTitle() {
      let string = '';
      if (this.$route.name === 'Categories') {
        return 'Categories';
      } else if (this.$route.name === 'Subcategories') {
        return this.$route.params.sub;
      } else if (this.$route.name === 'Category Product') {
        string = this.$route.query.subcategories;
      } else if (this.$route.name === 'Products') {
        string = this.$route.query.tags;
        if (string == null) {
          string = this.$route.query.categories;
        }
        if (string == null) {
          return 'All Products';
        }
        return string;
      } else if (this.$route.name === 'Search') {
        return 'Search: ' + this.$route.query.title;
      } else if (this.$route.name === 'Product') {
        return null;
      } else if (this.$route.name.includes('User')) {
        return 'All Users';
      } else if (this.$route.name == 'Company') {
        return 'All Users';
      }

      return 'All ' + string;
    },
    category() {
      if (this.$route.name === 'Product') {
        if (this.$store.state.products.product) {
          return this.$store.state.products.product.categories[0];
        } else {
          return null;
        }
      }
      if (this.$route.name === 'Categories') {
        return null;
      }
      if (this.categoryPipeLine) {
        if (this.$route.params.sub) {
          return this.$route.params.sub;
        }
        return this.$route.query.categories;
      }

      return null;
    },
    subcategory() {
      if (this.$route.name === 'Category Product' && this.categoryPipeLine) {
        return this.$route.query.subcategories;
      }
      if (this.$route.name === 'Product') {
        if (this.$store.state.products.product) {
          return this.$store.state.products.product.subcategories[0];
        } else {
          return null;
        }
      }
      return null;
    },
    search() {
      if (this.$route.name === 'Search') {
        return 'Search: ' + this.$route.query.title;
      }
      return null;
    },

    productName() {
      if (this.$store.state.products.product) {
        return this.$store.state.products.product.title;
      } else {
        return null;
      }
    },
  },
  methods: {
    viewAll() {
      const query = {
        type: 'normal',
      };
      if (this.$route.params.sub !== 'category') {
        query.categories = this.$route.params.sub;
      }
      this.$router.push({ path: '/products', query });
      this.searchValue = null;
      this.showSearch = false;
    },
  },
  mounted() {
    if (this.$route.name == 'Search' || this.$route.name == 'Products') {
      this.categoryPipeLine = false;
    }
  },
};
</script>

<style scoped>
a {
  margin-left: 4px;
  margin-right: 4px;
}

a:first-of-type {
  margin-left: 0px;
}

.breadcrumb > *:last-child {
  font-weight: 500;
  color: #003d5d;
}
</style>
