var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"relative w-full bg-white",class:_vm.$route.name == 'Category Product' || _vm.$route.name == 'Products' || _vm.$route.name == 'Search'
      ? 'px-4 md:px-6 shadow-blur-glow md:shadow-none md:pt-8 pt-2 sm:pt-4'
      : _vm.$route.name == 'Product'
      ? 'shadow-none px-6 md:px-0 lg:pl-10'
      : 'px-4 md:px-12 lg:px-24 shadow-blur-glow md:shadow-none md:pt-8 pt-2 sm:pt-4'},[(_vm.pageTitle)?_c('h1',{staticClass:"font-medium leading-none sm:inline-block sm:mr-8",staticStyle:{"word-break":"break-word"}},[_vm._v(" "+_vm._s(_vm.pageTitle)+" ")]):_vm._e(),(_vm.$route.name === 'Categories' || _vm.$route.name === 'Subcategories')?_c('a',{staticClass:"mt-1 py-1 sm:inline-block font-medium text-sm cursor-pointer",on:{"click":_vm.viewAll}},[_vm._v(" View all products "),(_vm.pageTitle !== 'Categories')?_c('span',[_vm._v("in "+_vm._s(_vm.pageTitle))]):_vm._e()]):_vm._e(),_c('div',{staticClass:"breadcrumb overflow-x-auto whitespace-no-wrap py-2"},[_c('router-link',{attrs:{"to":"/"}},[_vm._v("Home")]),(_vm.categoryPipeLine)?_c('span',[_vm._v("»")]):_vm._e(),(_vm.categoryPipeLine)?_c('router-link',{attrs:{"to":{ name: 'Categories', params: { sub: 'category' } }}},[_vm._v("Categories")]):_vm._e(),(_vm.category != null)?_c('span',[_vm._v("»")]):_vm._e(),(_vm.category != null)?_c('router-link',{attrs:{"to":{ name: 'Subcategories', params: { sub: _vm.category } }}},[_vm._v(_vm._s(_vm.category))]):_vm._e(),(_vm.subcategory != null && _vm.$route.name != 'Product')?_c('span',[_vm._v("»")]):_vm._e(),(_vm.subcategory != null && _vm.$route.name != 'Product')?_c('span',{staticClass:"mx-1 text-primary-dark cursor-pointer"},[_vm._v(" "+_vm._s(_vm.subcategory)+" ")]):_vm._e(),(_vm.subcategory != null && _vm.$route.name === 'Product')?_c('span',[_vm._v("»")]):_vm._e(),(_vm.subcategory != null && _vm.$route.name === 'Product')?_c('router-link',{staticClass:"mx-1 text-primary-dark cursor-pointer",attrs:{"to":{
        name: 'Category Product',
        query: { type: 'normal', categories: _vm.category, subcategories: _vm.subcategory },
      }}},[_vm._v(" "+_vm._s(_vm.subcategory)+" ")]):_vm._e(),(_vm.search != null)?_c('span',[_vm._v("»")]):_vm._e(),(_vm.search != null)?_c('router-link',{attrs:{"to":{ name: 'Categories', params: { sub: _vm.category } }}},[_vm._v(" "+_vm._s(_vm.search)+" ")]):_vm._e(),(_vm.$route.name === 'Product')?_c('span',[_vm._v("»")]):_vm._e(),(_vm.$route.name === 'Product')?_c('span',{staticClass:"mx-1 text-primary-dark cursor-pointer"},[_vm._v(" "+_vm._s(_vm.productName)+" ")]):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }